import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private max = 0;
  private step = 0;

  private _progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() { }

  get progress(): Observable<number> {
    return this._progress$.asObservable();
  }

  updateProgress(progress?: number): void {
    if (!progress) {
      this._progress$.next(this._progress$.value + this.step <= 100 ? this._progress$.value + this.step : 0);
    } else {
      this._progress$.next(progress);
    }
  }

  setStepsCount(value: number): void {
    this.max = value;
    this.step = 100 / this.max;
  }

  reset(): void {
    this._progress$.next(0);
  }
}
