import {
  trigger, group,
  transition, animate, style, query
} from '@angular/animations';

const slideTo = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: '2.5rem',
      right: '2rem'
    })
  ], { optional: true }),
  query(':enter', [
    style({
      right: 'calc(-100% + 4rem)',
      height: '70%',
      top: '20%',
      bottom: '20%',
      width: 'calc(100% - 4rem)'
    })
  ], { optional: true }),
  group([
    query(':leave', [
      animate('400ms ease', style({
        right: 'calc(100% - 3rem)',
        height: '70%',
        top: '20%',
        bottom: '20%',
      }))
    ], { optional: true }),
    query(':enter', [
      animate('400ms ease', style({
        right: '0%',
        height: 'calc(100% - 2.5rem)',
        top: '2.5rem',
        bottom: '0%',
      }))
    ], { optional: true })
  ])
];

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', slideTo)
  ]);


